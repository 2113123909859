import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { HeaderTitle } from "components";
import { Layout } from "containers";
import { NewJobForm } from "containers";

const PageJobsNew = ({
  data: {
    config: { header, seo },
  },
}) => {
  const layoutProps = {
    footerProps: {
      isHidden: true,
    },
    seoProps: seo,
  };

  return (
    <Layout {...layoutProps}>
      <HeaderTitle {...header} />
      <NewJobForm />
    </Layout>
  );
};

PageJobsNew.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
      seo: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }),
};

export const jobsNewPageQuery = graphql`
  query JobsNewPageQuery {
    config: pagesYaml(id: { eq: "PageJobsNew" }) {
      header {
        heading
      }
      id
      seo {
        title
      }
    }
  }
`;

export default PageJobsNew;
